import {
  MediaCapture,
  MediaFile,
} from '@awesome-cordova-plugins/media-capture';
import { ForegroundNotificationService } from '../ForegroundNotification/foregroundNotification.service';
import { PlatformService } from '../Platform';
import { SfFeatureFlags } from '@simplifield/feature-flags';
import { FEATURE_FLAGS } from '@constants/feature-flags.constant';
import {
  Camera,
  CameraResultType,
  CameraSource,
  ImageOptions,
} from '@capacitor/camera';
import { FileSystemService } from '../FileSystem/file-system.service';
import {
  Camera as CordovaCamera,
  CameraOptions as CordovaCameraOptions,
} from '@awesome-cordova-plugins/camera';

const PHOTO_LIMIT_SIZE = 2000;
const PHOTO_LIMIT_QUALITY = 90;
const PHOTO_CONFIG: ImageOptions = {
  resultType: CameraResultType.Uri,
  source: CameraSource.Camera,
  quality: PHOTO_LIMIT_QUALITY,
  width: PHOTO_LIMIT_SIZE,
  height: PHOTO_LIMIT_SIZE,
  allowEditing: false,
  saveToGallery: false,
  correctOrientation: true,
};

const CORDOVA_PHOTO_CONFIG: CordovaCameraOptions = {
  destinationType: CordovaCamera.DestinationType.FILE_URI,
  sourceType: CordovaCamera.PictureSourceType.CAMERA,
  encodingType: CordovaCamera.EncodingType.JPEG,
  quality: PHOTO_LIMIT_QUALITY,
  targetWidth: PHOTO_LIMIT_SIZE,
  targetHeight: PHOTO_LIMIT_SIZE,
  allowEdit: false,
  saveToPhotoAlbum: false,
  correctOrientation: true,
};

export class CameraService {
  MediaCapture = MediaCapture;
  Camera = Camera;
  CordovaCamera = CordovaCamera;
  hasCordovaFeatureFlag = false;

  constructor(
    private $q: ng.IQService,
    private filesSystemService: FileSystemService,
    private foregroundNotificationService: ForegroundNotificationService,
    private $window: ng.IWindowService,
    private sfFeatureFlagsService: SfFeatureFlags,
    private SF_FEATURE_FLAGS: typeof FEATURE_FLAGS,
    private platformService: PlatformService
  ) {
    'ngInject';

    this.hasCordovaFeatureFlag = this.sfFeatureFlagsService.hasFeature(
      this.SF_FEATURE_FLAGS.CORDOVA_CAMERA
    );
  }

  getPhotoAsFile(): ng.IPromise<File> {
    if (this.hasCordovaFeatureFlag) {
      if (this.platformService.isiOS()) {
        return this.takePhotoWithCordovaPlugin();
      }

      return this.takeNativePhoto();
    }

    return this.takePhotoWithPlugin();
  }

  takeNativePhoto(): ng.IPromise<File> {
    this.foregroundNotificationService.startForegroundService();

    const defer = this.$q.defer<File>();
    const input = this.$window.document.createElement(
      'input'
    ) as HTMLInputElement & { capture: string };

    input.type = 'file';
    input.accept = 'image/*';
    input.capture = 'environment';
    input.addEventListener('change', () => {
      const reader = new this.$window.FileReader();
      const file = input.files ? input.files[0] : null;

      if (!file) {
        defer.reject();
        input.remove();
        this.foregroundNotificationService.stopForegroundService();
      }

      reader.onload = () => {
        defer.resolve(file as File);
        input.remove();
        this.foregroundNotificationService.stopForegroundService();
      };
      reader.readAsDataURL(file);
    });
    input.style.visibility = 'hidden';
    input.click();
    this.$window.document.body.appendChild(input);
    return defer.promise;
  }

  takePhotoWithCordovaPlugin(): ng.IPromise<File> {
    return this.CordovaCamera.getPicture(CORDOVA_PHOTO_CONFIG).then((path) =>
      this.filesSystemService.getBlobFromPath(path)
    ) as ng.IPromise<File>;
  }

  takePhotoWithPlugin(): ng.IPromise<File> {
    return this.Camera.getPhoto(PHOTO_CONFIG).then(
      (photo) =>
        this.filesSystemService.getBlobFromPath(
          photo.path as string
        ) as ng.IPromise<File>
    );
  }

  takeVideo(): ng.IPromise<MediaFile> {
    this.foregroundNotificationService.startForegroundService();
    return this.$q.resolve(
      this.MediaCapture.captureVideo()
        .then((videos) => videos[0])
        .finally(() => {
          this.foregroundNotificationService.stopForegroundService();
        })
    );
  }
}
